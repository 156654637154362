<template>
  <div class="corner-module">
    Copyright©2024 {{ domainConfig?.title =='NEWS'?formattedHost:domainConfig.title }}. All rights
    reserved.
    <!-- <div v-if="showDisclaimer" class="unauthorized">
      Any unauthorized reproduction of the content is prohibited.
    </div> -->
  </div>
</template>

<script>
import '@/css/module/foot-theme-1.scss';
import { mapGetters } from 'vuex'

export default {
  name: 'FootModule1',
  computed: {
    ...mapGetters({
      host: 'host',
      themeConfig: 'themeConfig',
      domainConfig: 'domainConfig',
      deviceType: 'deviceType',
    }),
    formattedHost () {
      if (!this.host) return 'News';
      const parts = this.host.split('.');
      if (parts.length > 0) {
        const firstPart = parts[0];
        return firstPart.charAt(0).toUpperCase() + firstPart.slice(1) + ' Media';
      }
      return '';
    }
  },
  methods: {
    showDisclaimer () {
      const allowedHosts = [
        'bytebuzznews.com',
        'pulsereport.news',
        'cosmomash.news',
        'stellanova.news',
        'heliovista.news',
        'orionterra.news',
        'gaiapulse.news',
        'nexgentechnews.com',
        'livewirefeed.news',
        'digitalwave.homes',
        'pulsebrief.news',
        'headlinehub.homes',
        'novanetwork.news',
        'primefeed.news',
        'insightdailyfeed.news',
        'horizonheadlines.news',
        'informedtoday.news',
        'newsnexus.info',
        'geoinsight.news',
        'astrascope.news'
      ];
      return allowedHosts.includes(this.host);
    }
  },
};
</script>
